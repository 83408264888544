export const scrambleText = (text: string): string => {
  const characters = text.split("");
  for (let i = characters.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [characters[i], characters[j]] = [characters[j], characters[i]]; // Swap characters
  }
  return characters.join("");
};

export const formatAsFreePrice = (priceString: string) => {
  const allZeros = priceString.replace(/\d/g, "0");
  return allZeros.replace(/0{2,}(?=[.,])/g, "0");
};

export const periodToTranslationKey = (period: string) => {
  return period.replace(/P(\d+)M/, "$1_months");
};

export const formatTestName = (testName: string) => {
  return testName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatVariantName = (variantName: string, testName: string) => {
  variantName = variantName.replace(`${testName}_`, "");

  return variantName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const removeDateYear = (date: string) => {
  const slashes = date.replace(/(\/|-)\d{4}/, "");
  const trailingSymbols = slashes.replace(/\d{4}./, "");
  return trailingSymbols.replace(/\d{4}/, "");
};
